import React from "react";
import "./Home.css";
import "../../utilities/variables.css";
import CsvTable from "../../components/Table/Table";
import { useCSVData } from "../../utilities/CSVContext";

function Home() {
  const { csvData } = useCSVData();
  return (
    <div className="home">
      <h1>
        Buy your new domain name today! <br /> For sale, by owner. No brokerage
        fees.
      </h1>
      <h2>(Click on the domain name below to see more details)</h2>
      <CsvTable csvData={csvData} />
    </div>
  );
}

export default Home;
