import React from "react";
import "./Table.css";

const CsvTable = ({ csvData }) => {
  function renderCellContent(cell, idx) {
    switch (idx) {
      case 0:
        return <a href={`/${cell.split(".")[0]}`}>{cell}</a>;
      case 1:
        // If you have specific content for case 1, implement here
        return <span>{cell.length > 0 ? `$${cell}` : "contact us"}</span>;
      default:
        return cell;
    }
  }
  return (
    <div>
      <table>
        <thead>
          {csvData.length > 0 && (
            <tr>
              <th key={0}>ID</th>
              {Object.keys(csvData[0]).map((header, index) =>
                index < 3 ? <th key={index}>{header}</th> : null
              )}
            </tr>
          )}
        </thead>
        <tbody>
          {csvData.map((row, index) => (
            <tr key={index}>
              <td className={index % 2 === 0 ? "background" : ""}>
                {index + 1}
              </td>

              {Object.values(row).map((cell, idx) =>
                idx < 3 ? (
                  <td key={idx} className={index % 2 === 0 ? "background" : ""}>
                    {renderCellContent(cell, idx)}
                  </td>
                ) : null
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CsvTable;
