import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useCSVData } from "./CSVContext";
import PageComponent from "../pages/PageComponent/PageComponent";
import Home from "../pages/Home/Home"; //
import HighValue from "../pages/HighValue/HighValue";
import MakeOffer from "../pages/MakeOffer/MakeOffer";
import Lease from "../pages/Lease/Lease";
import Privacy from "../pages/Privacy/Privacy";
// import NotFoundComponent from './NotFoundComponent';  // Handle unmatched routes

const DynamicRoutes = () => {
  const { isLoading, error, uriList } = useCSVData() ?? {};

  if (isLoading) return <div>Loading routes...</div>;
  if (error) return <div>Error loading routes: {error}</div>;
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/premier-domains" element={<HighValue />} />
      <Route path="/make-offer" element={<MakeOffer />} />
      <Route path="/lease" element={<Lease />} />
      <Route path="/privacy" element={<Privacy />} />
      {uriList.map((uri, index) => (
        <Route
          key={index}
          path={uri}
          element={<PageComponent uri={uri} index={index} />}
        />
      ))}
      {/* <Route path="*" element={<NotFoundComponent />} /> */}
    </Routes>
  );
};

export default DynamicRoutes;
