// import React from "react";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./utilities/variables.css";

import NavBar from "./components/NavBar/NavBar";
import DynamicRoutes from "./utilities/DynamicRoutes";

import { CSVDataProvider } from "./utilities/CSVContext";

function App() {
  // Define an array of paths
  return (
    <CSVDataProvider>
      <Router>
        <div className="App">
          <header className="App-header">
            <div className="Header-gradiant">
              <div className="App-logo">
                <a href="/">FlipDomains&trade;</a>
              </div>
            </div>
            {/* create a nav bar */}
            <NavBar />
            <div class="Gradient-line"></div>
          </header>
        </div>
        <DynamicRoutes />
      </Router>
      <footer className="App-footer">
        <h3>FlipDomains&trade;</h3>
        <p>
          <br /> P.O. Box 17 Lafayette, CA 94549 USA
          <br />
          <br /> © 2007-2024 FlipDomains&trade;. All Rights Reserved
        </p>
      </footer>
    </CSVDataProvider>
  );
}

export default App;
