import React from "react";
import "./Privacy.css";
import "../../utilities/variables.css";

function Privacy() {
  return (
    <div class="privacy">
      <h1>Privacy Page</h1>
      <p>
        We will not share, trade, loan, give, sell or convey any personal
        identifiable information you give to us with any outside party. <br />
        We may use your email address to open an escrow transaction,{" "}
        <b>
          <u>but only if we are instructed to do so by you.</u>
        </b>
      </p>
      <p>P.O. Box 17 Lafayette, CA 94549 USA</p>
    </div>
  );
}

export default Privacy;
