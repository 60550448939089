import React from "react";
import "./HighValue.css";
import "../../utilities/variables.css";
import CsvTable from "../../components/Table/Table";
import { useCSVData } from "../../utilities/CSVContext";
// import JotFormEmbed from "../../components/ContactForm/JotForm";

function HighValue() {
  const { highValueDomains } = useCSVData();

  return (
    <>
      <div class="high-value">
        <h1>Premier Domains for Sale</h1>
      </div>
      <CsvTable csvData={highValueDomains} />
      {/* <JotFormEmbed formType="highValue" /> */}
    </>
  );
}

export default HighValue;
