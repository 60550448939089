import React from "react";
import "./MakeOffer.css";
import "../../utilities/variables.css";
import JotFormEmbed from "../../components/ContactForm/JotForm";

function MakeOffer() {
  return (
    <div class="make-offer">
      <h1>Interested in a specific Domain?</h1>
      <p className="margin">
        For sale, by owner. No brokerage fees. <br />
      </p>
      <JotFormEmbed formType="offer" />
    </div>
  );
}

export default MakeOffer;
