import React from "react";
import "./Lease.css";
import "../../utilities/variables.css";
import JotFormEmbed from "../../components/ContactForm/JotForm";

function Lease() {
  return (
    <div class="lease">
      <h1>Lease a Domain</h1>
      <JotFormEmbed formType="leasing" />
    </div>
  );
}

export default Lease;
