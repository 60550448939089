import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import "./NavBar.css";
import "../../utilities/variables.css";

function NavBar() {
  return (
    <div>
      {/* create a nav bar */}
      <ul id="nav">
        <li>
          <Link to="/">Domains for Sale</Link>
        </li>
        <li>
          <Link to="/premier-domains">Premier Domains</Link>
        </li>
        <li>
          <Link to="/make-offer">Make Offer</Link>
        </li>
        <li>
          <Link to="/lease">Domain Name Leasing</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy</Link>
        </li>
      </ul>
    </div>
  );
}

export default NavBar;
