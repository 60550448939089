import React from "react";
import "./PageComponent.css";
import "../../utilities/variables.css";
import { useCSVData } from "../../utilities/CSVContext";
import JotFormEmbed from "../../components/ContactForm/JotForm";

const PageComponent = (data) => {
  const { csvData, highValueDomains, isLoading } = useCSVData();
  // have to subtract index length here because the index is created from the dynamic routing
  // so the highValue dynamic routing won't work if the index isn't adjusted here.
  const csvIndex = csvData.length;
  const pageData =
    csvData[data.index] || highValueDomains[data.index - csvIndex];

  return isLoading ? (
    "Page Not Found"
  ) : (
    <div className="page-component">
      {/* <h1>{pageData ? "Interested in " + pageData.name : "Page not found"}</h1> */}

      <h4>Interested in</h4>
      <h1>{pageData ? pageData.name : ""}</h1>
      <h2>
        Selling Price:{" "}
        {pageData.price ? "$" + pageData.price : "contact us below"}
      </h2>

      <p className="margin">
        For sale, by owner. No brokerage fees. <br />
      </p>
      <JotFormEmbed
        formType="offer"
        domainName={pageData ? pageData.name : ""}
      />
    </div>
  );
};

export default PageComponent;
