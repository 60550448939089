import React, { createContext, useState, useContext, useEffect } from "react";
import Papa from "papaparse";

// Create the context
const CSVDataContext = createContext();

// Provider component
export const CSVDataProvider = ({ children }) => {
  const [csvData, setCsvData] = useState([]);
  const [highValueDomains, setHighValueDomains] = useState([]);
  const [uriList, setUriList] = useState([]);

  useEffect(() => {
    const fetchAndParseCSV = () => {
      // Fetching the CSV file from the public directory
      fetch("/flipDomains.csv")
        .then((response) => response.text())
        .then((text) => {
          // Parsing the CSV data
          Papa.parse(text, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              const uris = [];
              const domainList = [];
              const highValueList = [];
              results.data.forEach((row) => {
                row.highValue = row.highValue.toLowerCase();

                uris.push(row.uri);

                row.highValue === "true"
                  ? highValueList.push(row)
                  : domainList.push(row);
                // if (row.highValue === "true") {
                //   highValueList.push(row);
                // } else {
                //   uriList.push(row);
                // }
              });

              setUriList(uris);
              // setCsvData(results.data);
              setCsvData(domainList);

              setHighValueDomains(highValueList);
            },
          });
        })
        .catch((err) =>
          console.error("Error while fetching and parsing CSV", err)
        );
    };
    fetchAndParseCSV();
  }, []);

  return (
    <CSVDataContext.Provider value={{ csvData, highValueDomains, uriList }}>
      {children}
    </CSVDataContext.Provider>
  );
};

// Custom hook to use the context
export const useCSVData = () => useContext(CSVDataContext);
