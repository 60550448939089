import React, { useState, useEffect, useMemo } from "react";

const JotFormEmbed = ({ formType = "offer", domainName }) => {
  const formOptions = useMemo(
    () => ({
      offer: "https://form.jotform.com/241405972110144",
      leasing: "https://form.jotform.com/241406357115147",
      highValue: "https://form.jotform.com/241405972110144",
    }),
    []
  );

  const [selectedForm, setSelectedForm] = useState(formOptions[formType]);

  useEffect(() => {
    setSelectedForm(formOptions[formType]);
  }, [formType, formOptions]);

  return (
    <div>
      <iframe
        title="JotForm"
        src={selectedForm}
        width="100%"
        height="1450px"
        frameBorder="0"
        style={{ border: "none" }}
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default JotFormEmbed;
